import * as React from 'react';
import logo from './logo.svg';
import {Box, Typography, Grid, SpeedDial, SpeedDialAction, Tooltip, Link, Modal } from '@mui/material';
import SelfImprovementIcon from '@mui/icons-material/SelfImprovement';
import BalanceIcon from '@mui/icons-material/Balance';
import CallIcon from '@mui/icons-material/Call';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import yoga from './yoga.png';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailIcon from '@mui/icons-material/Email';
import illu from './illu.png';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import NearMeIcon from '@mui/icons-material/NearMe';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

import './App.css';
const headerStyle = {
  backgroundImage: `url(${yoga})`,
  backgroundPosition:'top',
  backgroundSize:"auto",
  backgroundRepeat: "no-repeat",
  height:"100vh",
  width:"100%",
  alignItems:"start",
  justifyContent:"space-between",
}

const BoxFlexStyle= {
  display:"flex",
  flexDirection:"column",
  justifyContent:"center",
  width:"100%",
  alignItems:"center",
  borderRadius:"8px",
  height:"18rem",
  boxShadow:"4px 4px 12px #f1f3f6"
}

const actions = [
  { icon: <InfoOutlinedIcon />, name: 'Impressum' },
  { icon: <CallIcon />, name: 'Kontakt' },
  { icon: <SelfImprovementIcon />, name: 'Yoga' },
  { icon: <AccessTimeIcon />, name: 'Kurse'},
];

const App = () => {
  const [open, setOpen] = React.useState(false);
  const [isImpressum, setImpressum] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = (e) => {
    const element = document.getElementById(e.currentTarget.id + "-label");
    const IdToPick = element.innerHTML;
    const myElement = document.getElementById(IdToPick);
    if (IdToPick === "Impressum"){
      setImpressum(true);
      setOpen(false)
    }
    myElement?.scrollIntoView()
    setOpen(false)
    
  }
  const handleCloseModal = () =>{
    setImpressum(false)
  }
  return (
<>
<Modal
 
  open={isImpressum}
  onClose={handleCloseModal}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
<Box style={{width:"100%", maxWidth:"600px", height:"600px", backgroundColor:"#fff", margin:" 1rem auto", padding:"2rem", overflowY:"scroll"}}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
              IMPRESSUM
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          Verantwortlich für den Inhalt (gem. § 55 Abs. 2 RStV):
          </Typography>
          <Typography id="modal-modal-contact" sx={{ my: 2}}>
          Heike Schoenen (Kontaktdaten wie oben) <br />
          Bildrechte:<br />
          www.unsplash.com
          </Typography>

          <Typography id="modal-modal-creator">
          Gestaltung und Umsetzung:<br/>
          Mio Mideal<br/>
          www.miomideal.com
          </Typography>
          <br />

          <Typography id="modal-modal-title" variant="h6" component="h2">
          Datenschutzerklärung
          </Typography>
<Typography id="modal-modal-description" sx={{ mt: 2 }}>

          Datenschutz:
          Datenerhebung und -verwendung personenbezogener Daten
          <br />
          Unsere Webserver speichern temporär jeden Zugriff in einer Protokolldatei. Folgende Daten werden erfasst und bis zur automatisierten Löschung gespeichert:
<br/>
          IP-Adresse des anfragenden Rechners, Datum und Uhrzeit des Zugriffs, Name und URL der abgerufenen Daten, Übertragene Datenmenge, Meldung, ob der Abruf erfolgreich war, Erkennungsdaten des verwendeten Browser- und Betriebssystems, Webseite, von der aus der Zugriff erfolgt sowie Name Ihres Internet-Zugangs-Providers.
<br/>
Die Verarbeitung dieser Daten erfolgt zum Zweck, die Nutzung der Webseite zu ermöglichen (Verbindungsaufbau), der Systemsicherheit sowie der technischen Administration der Netzinfrastruktur.
<br/>
Weiteren als die obenstehend aufgezählten personenbezogenen Daten werden nur erhoben, wenn Sie uns diese von sich aus mitteilen. Diese Daten werden in verschlüsselter Form übertragen. Personenbezogene Daten werden ausschließlich zur Bearbeitung Ihrer Anfragen genutzt. Ebenso erfolgt eine Weitergabe der Daten an Dritte ausschließlich zu diesem Zweck. Eine sonstige Weitergabe oder ein Verkauf der personenbezogenen Daten findet nicht statt.

          </Typography>
        </Box>
</Modal>
  <div id="App">
     <SpeedDial
        ariaLabel="SpeedDial tooltip"
        sx={{ '& .MuiFab-primary': { backgroundColor: '#b0c27e', color: 'fde3ca' } ,position: 'fixed', bottom: 16, right: 16, }}
        icon={<NearMeIcon  />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            tooltipOpen
            onClick={handleClose}
          />
        ))}
      </SpeedDial>
  <header style={headerStyle}>
<Box sx={{width:"95%",marginTop:"2rem", display:"flex", justifyContent:"right",}}>
  
  <img src={logo} width="250" alt="brand-logo" />
  
</Box>
    <span className="source-span">Image Source: https://unsplash.com/</span>
    </header>
    <main style={{minHeight:"50vh", height:"100%", width:"100%", margin:"auto", textAlign:"center"}}>
      
         <section id="Kurse">
          <Box sx={{width:"100%", my:4}}>

    <Typography sx={{p:4, fontWeight:"bold"}} variant="h2">Infos zum Kurs</Typography>

    <Grid container rowSpacing={2} columnSpacing={{ xs: 0, sm: 0, md: 2 }}sx={{width:"100%"}}>
      <Grid item sm={6} sx={{width:"100%"}}>
        <Box style={BoxFlexStyle} sx={{height:"18rem", backgroundColor:"#fde3ca"}}>
          <Typography variant="h5" sx={{fontWeight:"bold"}}>Montags</Typography>
          <Typography sx={{my:1}} variant="p">17:00 Uhr - 18:30 Uhr</Typography>
          <Typography variant="p">(Kurs für Anfänger*Innen)</Typography>
        </Box>
      </Grid>
      <Grid item sm={6} sx={{width:"100%"}}>
        <Box style={BoxFlexStyle} sx={{height:"18rem", backgroundColor:"#fde3ca"}}>
          <Typography variant="h5" sx={{fontWeight:"bold"}}>Mittwochs</Typography>
          <Typography sx={{my:1}} variant="p">15:00 Uhr - 16:15 Uhr</Typography>
          <Typography variant="p"><Link underline="none" target="_blank" rel="noreferrer" sx={{color:"#b0c27e"}} href="https://kghw.saarland/">(Kneipp-Gemeinschaft Hostenbach-Wadgassen e.V.)</Link></Typography>
        </Box>
      </Grid>  
    </Grid>
    </Box>

    </section>

    <section id="Yoga" style={{backgroundColor:"#fde3ca"}}>
    <Box >

    <Typography sx={{p:4, fontWeight:"bold"}} variant="h2">Warum Yoga?</Typography>

    <Grid container rowSpacing={2} columnSpacing={{ xs: 0, sm: 2, md: 2 }}>
      <Grid item sm={4}>
        <Box style={BoxFlexStyle} sx={{ backgroundColor:"#fff"}}>
        <Typography variant="p" ><FavoriteBorderIcon sx={{color:"#b0c27e" , fontSize:"4rem"}} fontSize='large'/></Typography>
          <Typography variant="h5" sx={{fontWeight:"bold"}}>Gesünder leben*</Typography>

          
          <Typography variant="p" sx={{p:2 ,textAlign:"center"}}>Asana-Yoga ist eine körperliche Yoga-Praxis, bei der die Posen über einen längeren Zeitraum gehalten werden.</Typography>
          
        </Box>

        
      </Grid>
      <Grid item sm={4}>
        <Box style={BoxFlexStyle} sx={{height:"18rem", backgroundColor:"#fff", borderRadius:"8px", boxShadow:"4px 4px 12px #f1f3f6"}}>
        <Typography variant="p" ><SelfImprovementIcon sx={{color:"#b0c27e" , fontSize:"4rem"}} fontSize='large' /></Typography>
          <Typography variant="h5" sx={{fontWeight:"bold"}}>Stress mindern</Typography>
         
          
          <Typography variant="p" sx={{p:2 ,textAlign:"center"}}>Diese Art von Yoga ist perfekt für alle, die einen körperlichen Ausgleich suchen, da sie ziemlich anstrengend sein kann.</Typography>
        </Box>
      </Grid>
      <Grid item sm={4}>
        <Box style={BoxFlexStyle} sx={{height:"18rem", backgroundColor:"#fff", borderRadius:"8px", boxShadow:"4px 4px 12px #f1f3f6"}}>
        <Typography variant="p"><BalanceIcon sx={{color:"#b0c27e", fontSize:"4rem"}} fontSize='large' /></Typography>
          <Typography variant="h5"  sx={{fontWeight:"bold"}}>Balance finden</Typography>
          <Typography variant="p" sx={{p:2 ,textAlign:"center"}}>Asana-Yoga kann auch eine gute Möglichkeit sein, Flexibilität, Kraft und Gleichgewicht zu verbessern.</Typography>
        </Box>
      </Grid>
      <Typography variant="p" sx={{padding:"1rem" ,textAlign:"left", fontSize:"0.8rem"}}>*Yoga kann den Besuch bei einem Arzt nicht ersetzen</Typography>

    </Grid>
    </Box>

    </section>
    <section id="Kontakt" >
    <Box>
      <Grid container spacing={2} sx={{alignItems:"center"}} >
<Grid item sm={4} style={BoxFlexStyle} >
<Typography variant="p"><CallIcon sx={{color:"#fde3ca", fontSize:"4rem"}} fontSize='large' /></Typography>

    <Typography variant="h5" sx={{fontWeight:"bold"}}>Noch Fragen?</Typography>   
    <Typography variant="p" sx={{p:2,textAlign:"center"}} >Wenn du am Yogakurs Balance und Zeit teilnehmen möchtest, oder noch Fragen zum Kurs offen sind, dann kontaktiere mich gerne!</Typography>
    <Box>
      <Tooltip title="Anrufen">
        <Link href='tel:01627447625'>
        <WhatsAppIcon fontSize="large" sx={{m:1, color:"#b0c27e"}}/>
        </Link>
      </Tooltip>
      <Tooltip title="Email">
      <Link href='mailto:info@balance-und-zeit.de'>
      <EmailIcon fontSize="large" sx={{ m:1, color:"#b0c27e"}} />

        </Link>
      </Tooltip>
    </Box>
</Grid>
<Grid item sm={8}>
  <Box >
<img src={illu} width="80%" alt="yoga illustration" />
  </Box>
</Grid>
      </Grid>
      </Box>
    </section>
  
    </main>
    <footer>
    <Box sx={{width:"95%",marginBottom:"3rem", display:"flex", justifyContent:"center",}}>
  
  <img src={logo} width="250" alt="brand-logo" />
  
</Box>

    </footer>

  </div>
  
  </>
  )
}

export default App